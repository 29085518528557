// Variables used in the project

// Colors variables
$color-primary: #4D19FF;
$color-secondary: #D6E4FD;
$color-accent: #E9967A;
$color-white: #FFFFFF;
$color-black: #000000;
$color-black-variant-1: #343434;
$color-invalid: #DB464B;
$color-shadow: #D0D0D0;
$color-shadow-variant-1: #D6D6D6;
$color-border: #CED4Da;
$color-placeholder: #6C757D;
$color-background-primary: #EFEDED;
$color-background-secondary: #FFFFFF;
$color-background-tertiary: #D6E4FD;
$color-background-quaternary: #F2F7F8;
$color-success: #77C96F;
$color-success-lighter: #AFF3B4;
$color-sub-title: #959595;

$dark-green: #55A19A;
$light-green: #E4F0EF;

$background-color-primary: #FCF2EA;
$background-color-primary-profile: #FCF2EA;
$background-color-secondary: #EBEBEB;
$color-font-secondary: #2E2E2E;

@if $COMPANY == "lep" {
  $background-color-primary-profile: #F4F6F8;
  $background-color-primary: $light-green;
  $background-color-secondary: $light-green;
  $color-font-secondary: $dark-green;
  .ebureau-titre-section div{
    display: none;
  }
}

// Size variables
$height-header: 70px;
$page-title-size: 1.5rem;
$margins-content: (
    top: 0,
    right: 3vw,
    left: 3vw,
    bottom: 3vh
);
