@mixin box-shadow {
    box-shadow: 0 3px 6px $color-shadow;
}
@mixin display-flex($justifyContent: center, $alignItems: center) {
    display: flex;
    justify-content: $justifyContent;
    align-items: $alignItems;
}
@mixin btn($borderRadius: 1rem, $background: $color-white) {
    @include box-shadow;
    border-radius: $borderRadius;
    background-color: $background;
    cursor: pointer;

}
@mixin btn-flex($justifyContent: center, $alignItems: center) {
    @include display-flex($justifyContent, $alignItems);
}
@mixin btn-image {
    height: 100%;
    width: auto;
}

@mixin btn-text {
    color: $color-primary;
    font-weight: 600;
    font-size: .9rem;
}

@mixin custom-scrollbar ($width: 300px, $height: 300px) {
    width: $width;
    height: $height;
    overflow-y: auto;
    padding-right: 15px;

    /* For Firefox*/
    scrollbar-color: #4D19FF #FFFFFF;
    scrollbar-width: thin;
    
    /* For Chrome and Safari */
    &::-webkit-scrollbar {
        width: 10px;
    }
    
    &::-webkit-scrollbar-track {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #4D19FF;
    }
}