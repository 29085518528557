$COMPANY:actusite;
@import "./utils/variables";
@import "./utils/mixins";

.auth-container {
    display: grid;
    grid-template-columns: 40% 60%;

    &__logo {
        position: absolute;
        top: 2.5%;
        right: 12.5%;
    }
    &__logo.logo-lep {
        height: auto;
        width: 150px;
    }

    &__left {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100vh;

        &__elliot {
            border-radius: 10px 0px 0px 10px;
            padding: 0 20% 0;
            position: relative;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-width: 1px 0 1px 1px;
            border-style : solid;
            border-color: #ededed;
            background: #FCF2EA;
            overflow: hidden;
            &__title {
                margin: auto 0;
                &-subtitle {
                    font-family: 'Urbanist';
                    font-size: 40px;
                    font-weight: 400 !important;
                    line-height: 48px;
                    text-align: center;

                    span {
                        display: block;
                        font-weight: 400;
                    }
                }

                &-icon {
                    text-align: center;
                }
            }

            &-image {
                position: relative;
                bottom : -5px;
                width: 100%;
                height: auto;
            }
        }
    }

    &__right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100vh;

        &__form {
            height: 70%;
            border-radius: 0px 20px 20px 0px;
            width: 85%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-width: 1px 1px 1px 0;
            border-style: solid;
            border-color: #ededed;
            background: #fff;
            &-title {
                font-size: 40px;
                font-weight: 400 !important;
                line-height: 48px;
                text-align: left;
                color: #2E2E2E;
            }

            &-subtitle {
                font-size: 14px;
                font-weight: 400;
                line-height: 16.8px;
                text-align: left;
                color: #919191;
                margin-top: 5px;
            }

            form {
                font-size: 13px;
                font-weight: 400 !important;
                line-height: 19.2px;
                text-align: left;
                width: 50%;

                .alert-success {
                    border: none;
                    background: transparent;
                }
                .form-control {
                    background: #FFFFFF !important;
                }
                .form-group--login {
                    .label-name {
                        font-size: 11px;
                        font-weight: 600 !important;
                        line-height: 19.2px;
                        text-align: left;
                    }
                    .label-name.light {
                        font-weight: 400 !important;
                    }
                    a.label-name {
                        color: initial;
                    }
                }
                .form-login-identifiant, .form-login-button {
                    margin: 40px 0;
                }
                .form-login-password {
                    display: flex;
                    justify-content: space-between;
                }
                .custom-control-label {
                    font-weight: 400 !important;
                }

                .btn-submit {
                    margin: 0 auto;
                    border: 0.5px solid #CBCBCB;
                    justify-content: center;
                    padding: 0 10px;

                    span {
                        font-size: 12px;
                        font-weight: 700 !important;
                        line-height: 30px;
                        margin-left: 15px;
                    }
                    
                    &:hover {
                        background : #D5F7E5;
                    }
                }
            }
        }

        &-bottom {
            position: absolute;
            bottom: 5%;
            width: 51%;
            text-align: center;

            &-question {
                font-size: 13px;
                font-weight: 400 !important;
                line-height: 19.2px;
                margin: 0 auto;
                width: 100%;
                display: flex;
                padding-bottom: 10px;
                justify-content: center;
            }

            &-contact {
                display: flex;
                border: 0.5px solid #CBCBCB;
                font-size: 14px;
                font-weight: 400 !important;
                line-height: 16.8px;
                text-align: left;
                color: #000000;
                justify-content: center;
                align-items: center;
                width: 25%;
                margin: 5px auto 0;
                border-radius: 10px;
                padding: 5px;

                &:hover {
                    color: #000000;
                }

                span {
                    margin-left: 5px;
                }
            }

            &-contact:hover {
                background: #D5E5FF;
            }
        }
    }

    &__lep {
        .auth-container__left__elliot {
            background: $light-green;
        }

        .auth-container__left__elliot__title-subtitle {
            color: $dark-green;
        }

        .custom-control-input:checked ~ .custom-control-label::before {
            border-color: $dark-green;
            background-color: $dark-green;
        }

        .auth-container__left__elliot__title {
            display: flex;
            gap: 10px;
            flex-direction: column;
        }

        .auth-container__left__elliot__title-subtitle {
            font-size: 34px;
        }

        a {
            color: $dark-green;
        }

        .logo-lep{
            position: relative;
            top: 0;
            right: 0;
            margin-bottom: 30px;
        }
    }
}

.auth-footer-forgotPassword {
    bottom: 25px;
    position: relative;

    .footer-question {
        font-size: 13px;
        color: #98a6ad;
        text-align: center;
        margin-bottom: 15px;
    }

    .footer-btn-contact {
        @include display-flex;
        height: 39px;
        width: 350px;
        border-radius: 16px;
        text-align: center;
        background-color: $color-secondary;
        color: #343434;
        font-weight: 500;
        border: none;
        left: 50px !important;
        position: relative;

        &:hover {
            background-color: $color-secondary;
            color: #343434;
            font-weight: 500;
            border: none;
        }
    }
}

.alert {
    padding: .5rem 1rem !important;
    margin: .5rem !important;
    text-align: center;
}

#errorConfirmationPassword, #errorPassword {
    display: none;
    color: red;
    font-weight: 400;
}

.flags {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    position: absolute;
    top: 3.5%;
    left: 14.5%;
}
.flag {
    height: 18px;
    width: auto;
    cursor: pointer;
    margin: 0 10px;
    transition: transform 0.3s;
}
.flag-with-hover:hover {
    transform: scale(1.2);
}
