$COMPANY:actusite;
@import "./utils/variables";
@import "./utils/mixins";
@import "./app";

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Spectral&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

svg.icon-ebureau {
    color: #2e2e2e;
    height: 35px;
    width: 35px;
    padding: 8px;
    border-radius: 5px;
}

img.icon-ebureau {
    color: #2e2e2e;
    height: 35px;
    width: 35px;
    border-radius: 5px;
}

#ckf-modal {
    z-index: 1000000 !important;
}

.ckf-file-preview-root {
    z-index: 1000001 !important;
}

body {
    font-family: "Urbanist", sans-serif !important;
}

p {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

html body[data-sidebar-size="condensed"] .content-page {
    margin-left: 0 !important;
    padding-top: 0;
}

html body.siteInternet_pages .content-page {
    padding: 0;
}

input[type=date] {
    cursor: pointer;
}

label {
    font-weight: 400 !important;
}

body .modal {
    height: calc(100% - 3vw);
}

.btn-primary {
    background-color: #4D19FF !important;
    border-color: #4D19FF !important;
    color: #FFFFFF !important;
    box-shadow: 0 0 0 #4D19FF !important;
}

.content-page {
    min-height: calc(100vh - 70px) !important;
    height: calc(100vh - 70px) !important;
}

body .content-page {
    overflow: visible;
    margin: 0;
    margin: 0 0 auto 80px !important;
    padding: 0;
}

body[data-sidebar-size="condensed"] .content-page .content {
    margin: 0 0 auto 80px;
}

.table-data__header {
    font-weight: 500;
}

.shadow-h:hover {
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, .075) !important;
}

.dropdown-toggle::after {
    display: none ! important;
}

.table .thead-light th {
    color: #ffffff !important;
    background-color: #0e234c !important;
    border-color: #0e234c !important;
}

.bootstrap-table .fixed-table-pagination>.pagination {
    float: initial !important;
    display: flex;
    padding-left: 0px;
    border-radius: 0.25rem;
    justify-content: center;
    margin-top: 1.5rem !important;
}

.fixed-table-pagination .pagination .page-item.active .page-link {
    font-weight: 600;
    color: #323a46;
    background-color: transparent;
}

.fixed-table-pagination .pagination .page-link {
    background-color: transparent !important;
}

.bootstrap-table .fixed-table-container .fixed-table-body {
    border-radius: 0.25rem !important;
}

.nav-pills>li>a {
    font-weight: inherit !important;
}

.nav-pills>a {
    font-weight: inherit !important;
}

.nav-pills .line .line-btn {
    margin-bottom: 25px;
}

.header-title {
    color: #4D19FF !important;
    font-size: 14px !important;
}

label {
    font-weight: 400;
}

.dd-list .dd-item .dd-handle {
    font-weight: 400 !important;
    color: #171717;
}

.text-success {
    color: #2fcc66 !important;
}

.nav-second-level a:hover {
    text-decoration: none;
}

.navbar-custom .topnav-menu button.nav-link,
.navbar-custom li a.nav-link {
    font-size: 0.9rem;
}

.container-alert {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    z-index: 1002;
}

.container-alert .alert {
    z-index: 1002;
}

.container-alert .alert-error {
    background-color: #dc1818;
    border-color: #dc1818;
}

.ck-editor__editable {
    min-height: 200px;
}

table .tr-bt {
    border-bottom: 2px solid #DCDCDC;
}

.btn-action {
    background-color: transparent;
    color: #98A6AD;
    font-size: 16px;
}

.pagination-detail {
    padding-left: 0.85rem;
}

.container-alert .btn-close {
    position: absolute;
    right: 10px;
}

//Bootstrap default var and componant
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


//Bootstrap perso var and componant
@import "./ubold/src/scss/config/default/variables";


//Bootstrap général
@import "~bootstrap/scss/bootstrap";


//Fonts
@import "./ubold/src/scss/custom/fonts/_fonts";


//Général
@import "~simplebar/dist/simplebar";
@import "./ubold/src/scss/app.scss";
@import "./ubold/src/scss/icons.scss";


//color picker
@import "~bootstrap-colorpicker/dist/css/bootstrap-colorpicker";
@import "./ubold/src/scss/custom/plugins/colorpicker";


// select2
@import "~select2/dist/css/select2";
@import "./ubold/src/scss/custom/plugins/select2";


//Nestable
@import "./ubold/src/scss/custom/plugins/nestable-list";


//
@import "~dropzone/dist/dropzone";
@import "./ubold/src/scss/custom/plugins/dropzone";

.btn-rounded-tooltips,
.btn-rounded-tooltips:hover,
.btn-rounded-tooltips:active:focus {
    border-radius: 100% !important;
    background-color: transparent;
    border: none;
    font-size: 18px;
    color: #002261;
    padding: 0 !important;
    box-shadow: none !important;
    cursor: default !important;
}

.btn-cancel {
    background-color: #b90000;
    border-color: #b90000;
    color: white;
}

.btn-cancel:hover {
    color: #fff;
    background-color: #ad0000;
    border-color: #ad0000;
}

.custom-switch .custom-control-label::before {
    cursor: pointer;
}

.custom-switch .custom-control-label::after {
    cursor: pointer;
}

.select2-container--default .select2-selection--multiple {
    cursor: pointer;
}

.select2-selection--multiple:before {
    content: "";
    position: absolute;
    right: 7px;
    top: 42%;
    border-top: 5px solid #888;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
}


//NEW STYLE
.menu-top-container {
    width: 100%;
    height: 70px;
    opacity: 1;

    &__icons {
        position: absolute;
        right: 3vw;
        top: 20px;
        display: flex;

        &__icon {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin: 0 5px;

            a {
                text-decoration: none;
            }

            &__logo {
                padding: 5px;
                margin: 0 auto;
                font-size: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 20px;
                    height: 20px;
                    color: #2e2e2e;
                }
            }

            &__title {
                color: #4D19FF;
                font-size: 11px;
                margin-right: 10px;
            }

            &__title--minisite-name {
                padding: 5px 10px;
                text-align: center;
                font-size: 16px;
                margin: auto 0;
                color: #2e2e2e;
                cursor: default;
            }
        }

        &__icon:last-child {
            margin: 0 2vh;
        }
    }
}

.connector {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 58px;
    padding-left: 7px;
}

.siteInternet_pages,
.siteInternet_navigation {
    .menu-mini-left-container {
        height: 60px !important;
    }
}

.menu-mini-left-container {
    display: flex;
    position: fixed;
    top: 5px;
    height: calc(100vh - 10px);
    padding-bottom: 0;
    left: 20px;
    width: 60px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #ededed;
    opacity: 1;
    z-index: 10;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 50.5px;

    &__logo {
        margin: 12px 0 20px;
        text-align: center;

        img {
            height: 36px;
            width: 36px;
        }
    }

    &__logo.lep-logo {
        img {
            width: 25px;
            height: auto;
        }
    }

    &__elements {
        &-element {
            cursor: pointer;
            position: relative;
            display: flex;
            justify-content: center;
            margin: 25px 0;

            &__logo {
                svg {
                    padding: 8px;
                    height: 35px;
                    width: 35px;
                    border-radius: 5px;
                }
            }

            a {
                text-decoration: none;
                color: #000000;
            }

            &__title {
                display: none;
                margin-left: 70px;
                font-size: 14px;
                width: 65%;
                margin-top: 9px;
            }

            &__subelement.subelement-multiple {
                a {
                    width: 100%;
                }
            }

            &__subelement {
                display: none;
                background: white;
                border-radius: 5px;
                width: auto;
                position: static;

                &__title {
                    display: none;
                    width: 100%;
                    text-align: center;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    min-width: 100px;
                    padding: 2px 8px;
                    border-radius: 5px;
                    background-color: #2E2E2E;
                    color: white;
                    cursor: initial;
                }

                &__title.title-head {
                    padding: 2px 8px;
                }

                &__title.title-link {
                    cursor: pointer;
                }

                &__subtitle {
                    width: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    border-radius: 0;
                    padding: 5px 10px;
                }

                &__subtitle:hover {
                    background-color: $background-color-secondary;
                    color: $color-font-secondary;
                }
            }
        }

        &-element:hover {

            svg:not(.svg-logout),
            img {
                background-color: $background-color-secondary;
                color: $color-font-secondary;
            }
        }
    }
}

.menu-mini-left-container:hover {
    overflow: visible !important;
    height: calc(100vh - 10px) !important;
}

.trigger-subelement:hover {
    .menu-mini-left-container__elements-element__subelement {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 5px;
        min-width: 100px;
    }

    .menu-mini-left-container__elements-element__subelement__title {
        display: flex;
    }
}

.menu-mini-left-container__elements-element.element-active::after {
    border-radius: 2px 0px 0px 2px;
    background: #2E2E2E;
    width: 4px;
    height: 25px;
    top: 5px;
    position: absolute;
    right: 0;
    content: "";
}

.arrow {
    border: solid #919191;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
}

.rotate-in {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transition: transform 0.5s;
}

.rotate-out {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transition: transform 0.5s;
}

.utilities-container {
    position: fixed;
    z-index: 10;
    top: 25vh;
    right: 0;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 61px 0px 0px 61px;
    height: 15vh;
    width: 1.5vw;

    &__icon {
        margin-top: 3vh;
        margin-left: 0.3vw;

        img {
            width: 20px;
            height: 20px;
        }
    }

    &__icon:last-child {
        margin-top: 2vh;
    }

    &__separator {
        content: "";
        width: 0.9vw;
        border-bottom: 1px solid lightgray;
        text-align: center;
        margin: 2vh auto 0;
    }
}


.navbar-custom {
    display: none;
}

.left-side-menu {
    display: none;

}

// Button

.line {
    width: 100%;
    @include display-flex($justifyContent: flex-start);
    flex-wrap: wrap;
}

.line-btn .nav-link-style {
    @include display-flex;
    flex-wrap: nowrap;
    min-width: 180px;
    height: 37px;
    margin: 0 5px;
    padding: 15px 10px;
    background-color: transparent;
    box-shadow: 0px 3px 6px $color-shadow;
    border: 0.5px solid $color-black-variant-1;
    border-radius: 18px;
    opacity: 1;

    &:hover {
        background: $color-background-tertiary 0% 0% no-repeat padding-box;
        opacity: 1;
        cursor: pointer;
        border: 0.5px solid $color-secondary;
    }

    .content-icon-pills {
        display: flex;
        width: 50px;
        height: 300%;
        border-right: 0.1em solid $color-black-variant-1;
        align-items: center;
        justify-content: center;

        img {
            width: auto;
            height: 13px;
        }

        img.unactive {
            display: block;
        }

        img.active {
            display: none;
        }
    }

    .content-text-pills {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;

        p {
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0px;
            color: $color-black-variant-1;
        }
    }
}

.line-btn .nav-link-style.active {
    background: $color-background-tertiary 0% 0% no-repeat padding-box;
    box-shadow: 0 3px 6px $color-shadow-variant-1;
    border: 0.5px solid $color-secondary;
    border-radius: 18px;
    opacity: 1;
    color: $color-primary;
    display: flex;

    .content-icon-pills {
        border-right: 0.1em solid $color-primary;

        img.unactive {
            display: none;
        }

        img.active {
            display: block;
        }
    }

    .content-text-pills {
        p {
            color: $color-primary;
        }
    }
}

// Dropdown

.line {
    display: flex;
    width: 100%;

    .dropdown {
        .dropdown-menu {
            min-width: 200px;
            margin: 5px;
            padding: 0;
            box-shadow: 0px 3px 6px #4A4A4A45;
            border-radius: 7px;
        }
    }

    .dropdown.show {
        a.dropdown-toggle {
            background: $color-background-tertiary 0% 0% no-repeat padding-box;
            box-shadow: 0 3px 6px $color-shadow-variant-1;
            border: 0.5px solid $color-secondary;
            border-radius: 18px;
            opacity: 1;
            color: $color-primary;

            .content-icon-pills {
                border-right: 0.1em solid $color-primary;

                img.unactive {
                    display: none;
                }

                img.active {
                    display: block;
                }
            }

            .content-text-pills {
                p {
                    color: $color-primary;
                }
            }
        }
    }

    .dropdown:hover {
        a.dropdown-toggle {
            background: $color-background-tertiary;
            cursor: pointer
        }
    }
}


// Button menu

.btn-menu {
    @include display-flex;
    flex-wrap: nowrap;
    min-width: 180px;
    height: 37px;
    margin: 0 5px;
    padding: 15px 10px;
    background-color: transparent;
    box-shadow: 0px 3px 6px $color-shadow;
    border: 0.5px solid $color-black-variant-1;
    border-radius: 18px;
    opacity: 1;
    color: $color-black-variant-1;

    &:hover {
        background: $color-background-tertiary 0% 0% no-repeat padding-box;
        opacity: 1;
        cursor: pointer;
        border: 0.5px solid $color-secondary;
    }

    &__img {
        display: flex;
        width: 25px;
        align-items: center;
        border-right: 0.1px solid $color-black-variant-1;

        &.btn-menu--active {
            border-right: 0.1px solid $color-secondary;
        }

        img {
            width: auto;
            height: 13px;
            margin-right: 2rem;
            height: 13px;
        }
    }

    &__text {
        margin-left: 15px;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0px;
        color: $color-black-variant-1;
    }

    &--active {
        display: flex;
        background: $color-background-tertiary 0% 0% no-repeat padding-box;
        box-shadow: 0 3px 6px $color-shadow-variant-1;
        border: 0.5px solid $color-secondary;
        color: $color-primary;

        .btn-menu__img {
            border-right: 0.1px solid $color-primary !important;
        }

        .btn-menu__text {
            color: $color-primary !important;
        }
    }
}

// Btn action

.btn-submenu {
    @include btn($background: $color-background-tertiary);
    @include btn-flex($justifyContent: flex-start);
    min-width: 120px;
    box-shadow: 0px 3px 6px #E5E5E5;
    border-radius: 50px / 100px;
    opacity: 1;
    color: $color-primary;
    margin-right: 15px;

    &__img {
        display: flex;
        align-items: center;
        margin-right: 1rem;

        img {
            height: 19px;
            width: auto;

            &.hovered-img {
                display: none;
            }
        }
    }

    &__text {
        white-space: nowrap;
        font-weight: 600;
        font-size: .9rem;
    }
}

.btn-submenu:hover {
    background-color: $color-primary !important;
    color: $color-white !important;

    .btn-submenu__img {
        img {
            display: none;

            &.hovered-img {
                display: inline;
            }
        }
    }
}

.ebureau-link-primary {
    text-align: center;
    border: 1px solid #4D19FF;
    border-radius: 20px;
    font-size: 12px;
    background: #4D19FF;
    color: #fff;
    align-items: center;
    flex-wrap: nowrap;
    display: flex;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0;
    justify-content: center;
    padding: 10px 20px;
    vertical-align: middle;

    &:hover {
        color: #fff !important;
    }
}

.ebureau-link-secondary {
    text-align: center;
    border: 1px solid #4D19FF;
    border-radius: 20px;
    font-size: 12px;
    background: transparent;
    color: #4D19FF;
    align-items: center;
    flex-wrap: nowrap;
    display: flex;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0;
    justify-content: center;
    padding: 10px 20px;
    vertical-align: middle;
}

#loading {
    background: #f5f6f8;
    width: 100vw;
    height: 100vh;
    z-index: 100000;
    display: block;
    position: absolute;

    .loader-actusite,
    .loader-lep {
        &::after {
            content: "";
            width: 160px;
            height: 90px;
            box-sizing: border-box;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-size: cover;
        }
    }

    .loader-actusite {
        &::after {
            background-image: url(https://actusite-dev.fr/medias-ebureau/actusite-loader.gif);
        }
    }

    .loader-lep {
        &::after {
            background-image: url('../Images/Lep/loader/loader.gif');
        }
    }
}

.bootstrap-table .table:not(.table-sm)>tbody>tr>td,
.bootstrap-table .table:not(.table-sm)>tbody>tr>th,
.bootstrap-table .table:not(.table-sm)>tfoot>tr>td,
.bootstrap-table .table:not(.table-sm)>tfoot>tr>th,
.bootstrap-table .table:not(.table-sm)>thead>tr>td {
    padding: 10px 0.85rem;
}

.bootstrap-table .fixed-table-container .table thead th .th-inner {
    padding: 8px 0.75rem;
}

.alert-dismissible .close {
    padding: 0;
    top: 5px;
    right: 5px;
}

#reply_google_reviews {

    .auth-container__left__elliot__title-subtitle,
    .auth-container__right__form-title {
        font-size: 25px;
    }

    .auth-container__right__form-title {
        font-weight: 600 !important;
    }

    .form-login {
        display: flex;
        flex-direction: column;
        gap: 30px;
        font-size: 13px;
        font-weight: 400 !important;
        line-height: 19.2px;
        text-align: left;

        .title-container {
            display: flex;
            flex-direction: row;
            gap: 20px;
            align-items: center;
            margin-bottom: 50px;

            span {
                font-size: 25px;
                font-weight: 600;
                line-height: 30px;
            }
        }

        .reviewer-container,
        .reply-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            &--name {
                display: flex;
                flex-direction: row;
                gap: 10px;

                img {
                    height: 15px;
                    top: -2px;
                    position: relative;
                }
            }

            &--title {
                font-size: 13px;
                font-weight: 700;
                line-height: 15.6px;
            }

            &--content {
                width: 520px;
                min-height: 95px;
                border-radius: 20px;
                padding: 15px;
                border: 1px solid #EBEBEB;
            }
        }

        .reply-container {
            align-items: end;

            &--content {
                border: 1px solid #56E099;
            }
        }
    }

    .bouton-principal--vert {
        font-size: 25px;
        padding: 9px 12px 9px 12px;
        min-height: 54px;
        min-width: 310px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        img {
            width: 30px;
            height: 30px;
        }
    }
}

//HACK FOR CKFINDER DISABLED SETTINGS
.ui-icon-ckf-settings {
    display: none !important;
}