// Nestable css origin
.dd{position:relative;display:block;margin:0;padding:0;max-width:600px;list-style:none;font-size:13px;line-height:20px}.dd-list{display:block;position:relative;margin:0;padding:0;list-style:none}.dd-list .dd-list{padding-left:30px}.dd-empty,.dd-item,.dd-placeholder{display:block;position:relative;margin:0;padding:0;min-height:20px;font-size:13px;line-height:20px}.dd-handle{display:block;height:30px;margin:5px 0;padding:5px 10px;color:#333;text-decoration:none;font-weight:700;border:1px solid #ccc;background:#fafafa;border-radius:3px;box-sizing:border-box}.dd-handle:hover{color:#2ea8e5;background:#fff}.dd-item>button{position:relative;cursor:pointer;float:left;width:25px;height:20px;margin:5px 0;padding:0;text-indent:100%;white-space:nowrap;overflow:hidden;border:0;background:0 0;font-size:12px;line-height:1;text-align:center;font-weight:700}.dd-item>button:before{display:block;position:absolute;width:100%;text-align:center;text-indent:0}.dd-item>button.dd-expand:before{content:'+'}.dd-item>button.dd-collapse:before{content:'-'}.dd-expand{display:none}.dd-collapsed .dd-collapse,.dd-collapsed .dd-list{display:none}.dd-collapsed .dd-expand{display:block}.dd-empty,.dd-placeholder{margin:5px 0;padding:0;min-height:30px;background:#f2fbff;border:1px dashed #b6bcbf;box-sizing:border-box;-moz-box-sizing:border-box}.dd-empty{border:1px dashed #bbb;min-height:100px;background-color:#e5e5e5;background-size:60px 60px;background-position:0 0,30px 30px}.dd-dragel{position:absolute;pointer-events:none;z-index:9999}.dd-dragel>.dd-item .dd-handle{margin-top:0}.dd-dragel .dd-handle{box-shadow:2px 4px 6px 0 rgba(0,0,0,.1)}.dd-nochildren .dd-placeholder{display:none};


// 
// nestable-list.scss
//

.dd-list {
    .dd-item {
        .dd-handle {
            border: none;
            padding: 8px 16px;
            height: auto;
            font-weight: 600;
            border-radius: 3px;
            background: $dropdown-link-hover-bg;
            color: $dropdown-link-color;

            &:hover {
                color: $primary;
            }
        }
        button {
            height: 36px;
            font-size: 17px;
            margin: 0;
            color: $gray-600;
            width: 36px;
        }
    }
    .dd3-item {
        margin: 5px 0;

        .dd-item button {
            width: 36px;
            height: 36px;
        }
    }
    .dd3-handle {
        margin: 0;
        height: 36px !important;
        float: left;
    }
    .dd3-content {
        height: auto;
        border: none;
        padding: 8px 16px 8px 46px;
        background: $dropdown-link-hover-bg;
        color: $dropdown-link-color;
        font-weight: 600;
        &:hover {
            color: $primary;
        }
    }
    .dd3-handle:before {
        content: "\F035C";
        font-family: "Material Design Icons";
        color: $gray-500;
    }
}

.dd-empty,
.dd-placeholder {
    background: rgba($gray-400,0.2);
}

.dd-dragel {
    .dd-handle {
        box-shadow: $shadow;
    }
}