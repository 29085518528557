@import "./utils/variables";

body {
    background-color: #fafafa !important;
    color: #2e2e2e !important;
    font-size: 12px !important;
}

body .content-page .content {
    padding: 5px 20px 48px;
    overflow-y: auto;
    margin: auto;
    max-height: 900px;
    max-width: 1600px;
    height: 100%;
}

.title-page-container {
    left: 100px !important;
    position: absolute;
    top: 20px;
    display: flex;
    align-items: center;
    gap: 5px;

    &__title {
        color: #2e2e2e;
        font-size: 25px;
        font-weight: 600;
        line-height: 30px;
        padding: 2px 5px;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &__subtitle {
        color: #2e2e2e;
        align-items: center;
        padding: 2px 5px;
        gap: 5px;
        cursor: pointer;
        border-radius: 10px;
        display: flex;
        justify-content: center;

        &:hover {
            color: $color-font-secondary;
            background-color: $background-color-secondary;
        }

        button {
            background-color: #4D19FF;
            color: #FFFFFF;
        }
    }

    .separator {
        color: #919191 !important;
        background: transparent !important;
    }

    &__additionnal {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .container-img {
        height: 24px;
        width: 24px;
        border-radius: 5px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        &--orange {
            background: #FF9558;
        }

        &--jaune {
            background: #E2D900;
        }

        &--bleu {
            background-color: #58A9FF;
        }

        &--vert {
            background: #56E099;
        }

        &--violet {
            background: #A27DF8;
        }

        &--disabled {
            background: #EBEBEB !important;
        }

        img {
            width: 18px;
            height: 18px;
            margin: auto;
        }
    }
}
.dashboard-container-help{
    border-radius: 10px;
    border: 1px dashed #6C757D;
    padding: 20px;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 20px;
    align-items: center;
    height: fit-content;
    background: transparent;
    display: flex;

    &__title {
        font-size: 20px;
        text-align: center;
        position: relative;
        padding-bottom: 5px;

        span {
            font-style: italic;
            font-weight: 700;
        }

        .label{
            font-size: 20px;
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 80%;
            border-bottom: 2px solid black;
            transform: translateX(-50%);
        }
    }
    &__content {
        text-align: center;
        span {
            font-style: italic;
            font-weight: 700;
        }
    }
}
.dashboard-container-manager {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    border : 1px solid #EDEDED;
    color: #2E2E2E;
    cursor: auto;
    position: relative;
    padding: 10px;
    margin-top: 0;

    &__img {
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        width: 55px;
        height: 55px;
    }

    &__title {
        font-size: 12px;
        font-weight: 700;
        line-height: 15.6px;
        color: #2E2E2E;
        margin-bottom: 10px;
        padding-top: 0;
    }

    &__name {
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
        margin-top: 5px;
    }

    a {
        margin-top: 5px;
    }
}

.bouton-principal {
    padding: 10px 14px;
    font-size: 12px;
    line-height: 12px;
    color: #2e2e2e !important;
    font-weight: 600;
    background: #fff;
    border : 1px solid #EDEDED;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 10px;

    .title {
        display: block;
    }

    &:disabled {
        background: #dddddd;
        border: 0;
        cursor: auto;

        &:hover {
            background: #dddddd;
            border: 0;
            cursor: auto;
        }
    }

    p {
        white-space: nowrap;
    }

    &:hover {
        color : #2e2e2e!important;
        .title {
            display: none;
        }
    }

    &--right-top {
        position: absolute;
        right: 5px;
        top: 5px;
    }

    &--left-bottom {
        position: absolute;
        left: 5px;
        bottom : -40px;
    }

    &--right-bottom {
        position: absolute;
        right: -10px;
        bottom: -40px;
    }
    &--annuler{
         position : absolute;
         left: 10px;
         bottom: 10px;
    }

    &--center-right {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateX(-50%);
    }


    &--z-index {
        position: absolute;
        right: 30px;
        top: 30px;
        z-index: 999;
    }

    &--center {
        width: fit-content;
        position: absolute;
        left: 50%;
        bottom: 15px;
        transform: translateX(-50%);
        white-space: nowrap
    }

    &--center-auto {
        width: fit-content;
        margin: auto;
        white-space: nowrap
    }

    &--orange {
        span {
            color: #FF9558;
        }

        &:hover {
            background-color: #FFE5D5;
            border: 1px solid #FF9558;
        }
    }

    &--lep {
        background-color: $dark-green;
        color: #fff !important;

        span {
            img {
                width: 13px;
                height: 13px;
            }
        }

        &:hover {
            color: #fff !important;
        }
    }

    &--lep-main {
        border: 1px solid #dce0e4;

        span {
            img {
                width: 13px;
                height: 13px;
            }
        }

        &:hover {
            background-color: #dce0e4;
        }
    }
    &--rose {
        background-color: #FFD5FA;
        border: none;
        span {
            img{
                width: 13px;
                height: 13px;
            }
        }

        &:hover {
            background-color: #FFD5FA;
        }
    }

    &--jaune {
        span {
            color: #E2D900;
        }

        &:hover {
            background-color: #FFF7D1;
            border: 1px solid #E2D900;
        }
    }

    &--bleu {
        span {
            color: #58A9FF;
        }

        &:hover {
            background-color: #D5E5FF;
            border: 1px solid #58A9FF;
        }
    }

    &--vert {
        span {
            color: #56E099;
            img{
                width: 14px;
                height: 14px;
            }
        }
        &:hover {
            border: 1px solid #56E099;
            background: #D5F7E5;
        }
    }


    &--rouge {
        span {
            color: #FF5865;
        }

        &:hover {
            background-color: #FFD5D9;
            border: 1px solid #FF5865;
        }
    }

    &--ia {
        border: none;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        max-height: 22px;
        padding: 5px;
        span {
            img{
                width: 12px;
                height: auto;
            }
        }

        &:hover {
            background-color: #FFD5FA;
        }
    }
}

.ebureau-section {
    padding: 20px;
    background: #fff;
    border : 1px solid #EDEDED;
    border-radius: 10px;
    display: grid;
    grid-template-rows: 24px auto 32px;
    gap: 20px;
    overflow: hidden;
}

.ebureau-picto-section {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &--orange {
        background: #FF9558;
    }

    &--jaune {
        background: #E2D900;
    }

    &--bleu {
        background-color: #58A9FF;
    }

    &--vert {
        background: #56E099;
    }

    &--violet {
        background: #A27DF8;
    }

    &--pink {
        background: #FFD5FA;
    }

    &--purple {
        background: #4d19ff ;
    }

    &--disabled {
        background: #919191!important;
    }

    img{
        width: 18px;
        height: 18px;
        margin: auto;
    }
}

.ebureau-titre-section {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #2E2E2E;
    display: flex;
    align-items: center;
    gap: 10px;

    img {
        width: 15px;
        height: 15px;
    }
    div {
        height: 24px;
        width: 24px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &--bleu {
        background: #d5e5ff;
        color: #58A9FF;
    }

    &--orange {
        background: #FFE5D5;
        color: #FF9558;
    }

    &--jaune {
        background: #FFF7D1;
        color: #FF9558;
    }

    &--grey {
        background-color: $background-color-secondary;
        color: $color-font-secondary;
    }

    &--vert {
        background: #D5F7E5;
        color: #56E099;
    }

    &--violet {
        background: #E8DEFD;
        color: #A27DF8;
    }
}

.dashboard-container-profile {
    grid-template-rows: 24px auto;
    background: $background-color-primary-profile;
    height: fit-content;
    .ebureau-titre-section {
        span {
            font-size: 12px;
            font-weight: 400;
            line-height: 15.6px;
        }
    }

    .dashboard-container-information {
        flex-direction: row;
        display: flex;
        border-radius: 10px;
        padding: 0;
        gap: 10px;
        grid-template: initial;
        a {
            color: inherit;
        }
    }

    .dashboard-container-profile__item {
    display: flex;
    flex-direction: column;
    flex-basis: 33%;
    padding: 0;
    gap: 5px;
    align-items: center;
    text-align: center;
    }

    .dashboard-container-profile__name {
        font-size: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .dashboard-container-profile__logo {
        width: fit-content;
        position: relative;

        img {
            width: 25px;
            height: 25px;
        }

        &:before {
            content: url('../Images/icons_dashboard_v4/new/red.svg');
            position: absolute;
            width: 10px;
            height: 10px;
            top: -5px;
            right: -4px;
        }

        &--true:before {
            content: url('../Images/icons_dashboard_v4/new/green.svg');
        }
    }

    .dashboard-container-form {
        padding: 0;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    select {
        background: #FFFFFF;
        border: 1px solid #EBEBEB;
        padding: 5px 15px 5px 15px;
        gap: 10px;
        border-radius: 10px;
        margin: 0;
    }

    .dashboard-block__button {
        background-color: #fff;
        cursor: pointer;
        margin-top: 10px;
        width: fit-content;
        margin-bottom: 10px;
        align-items: center;
        display: flex;
        padding: 9px 12px 9px 12px;
        gap: 10px;
        border-radius: 10px;
        border: 1px solid #EBEBEB;
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;

        &:hover {
            background: #FFE5D5;
            border: 1px solid #FF9558
        }
    }
}

.dashboard-container-article,
.dashboard-container-publication,
.dashboard-container-newsletter,
.dashboard-container-review,
.dashboard-container-alert,
.dashboard-container-form,
.dashboard-container-page,
.dashboard-container-immo {
    position: relative;
    grid-template-rows: 24px auto 32px;
    flex-grow: 1;
    .dashboard-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
        gap: 10px;
        margin-top: 0;
        height: 100%;
        justify-content: space-between;
        &--item {
            background-color: #ffffff;
            width: 100%;
            color: #2E2E2E;
            position: relative;
            text-align: left;
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: left;
            box-shadow: none;
            border-radius: 10px;
            margin-top: 0;
            gap: 15px;
            padding: 0;
            &:hover {
                background-color: #ffffff;
            }

            &__img {
                max-width: 60px;
                width: 100%;
                height: 60px;
                flex-basis: 60%;
                position: relative;
                background-size: cover;
                background-position: center center;
                border-radius: 10px;
                img {
                    max-width: 60px;
                    width: 100%;
                    height: 60px;
                    border-radius: 10px;
                    border: 1px;
                    object-fit: cover;
                }

                .icon {
                    position: absolute;
                    width: 19px;
                    height: 19px;
                    right: 0;
                    top: 0;
                }
            }

            &__informations {
                flex-basis: 80%;
                flex-direction: column;
                display: flex;
                gap: 8px;
                justify-content: space-between;

                &--head {
                    font-weight: 400;
                    text-align: left;
                    color: #000000;
                    max-height: 33.6px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 12px;
                    line-height: 15px;
                }

                &--bottom {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                }

                &--category {
                    font-size: 8px;
                    line-height: 10px;
                    letter-spacing: 0em;
                    text-align: left;
                    background-color: $background-color-secondary;
                    color: $color-font-secondary;
                    width: fit-content;
                    border-radius: 10px;
                    padding: 5px 8px 5px 8px;
                    gap: 10px;
                }

                &--date {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 14.4px;
                    text-align: left;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 2px;
                }
            }
        }
    }
}

.dashboard-container-immo {
    .dashboard-list--item {
        &__informations {
            &--description {
                font-size: 10px;
                font-weight: 700;
                line-height: 9.6px;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

.dashboard-container-review {
    .dashboard-list {
        &--item__img {
            font-size: 40px;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: 0em;
            color: #58A9FF;
            display: flex;
            align-items: center;
            img {
                height: 30px;
            }
        }
    }
}

.dashboard-container-page {
    .dashboard-list--item {
        border: 0.5px solid #56E099;
        padding: 15px;
        gap: 20px;
        border-radius: 10px;

        &__informations {
            &--head {
                font-size: 12px;
                font-weight: 700;
                line-height: 14.4px;
                text-align: left;
            }

            &--head {
                font-size: 12px;
                font-weight: 700;
                line-height: 14.4px;
                text-align: left;
            }

            &--description {
                font-size: 8px;
                line-height: 14.4px;
            }
        }

        &:hover {
            background: #D5F7E5
        }
    }

    .dashboard-list--item__informations--view {
        position: absolute;
        bottom: 10px;
        right: 10px;
        height: 24px;
        width: 24px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #D5F7E5;

        img {
            margin-right: 2px;
        }
    }
}

.dashboard-container-newsletter {
    .dashboard-list--item {
        border: 0.5px solid #FF9558;
        padding: 12px 25px 12px 25px;
        gap: 20px;
        border-radius: 10px;
        &:hover {
            background: #FFE5D5;
            .dashboard-list--item__informations--category {
                background: #FFF;
            }
        }
    }

    .dashboard-list--item__informations--view {
        position: absolute;
        bottom: 10px;
        right: 10px;
        height: 24px;
        width: 24px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFE5D5;

        img {
            margin-right: 2px;
        }
    }
}


.menu {
    list-style: none;
    display: flex;
    padding-left: 0;
    margin-bottom: 0;

    &__item {
        margin-right: 20px;
        color: #2E2E2E;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        position: relative;
        &:hover {
            .menu__content {
                border-bottom : 2px solid #2e2e2e;
            }
            .dropdown-menu{
                display: block;
            }
        }
    }

    &__link {
        text-decoration: none;
        color: initial !important;

        &:hover {
            color: initial;
        }
    }

    .active {
        .menu__content {
            border-bottom : 2px solid #2e2e2e;
            font-weight: 600;
        }
    }
}

 .checkbox-wrapper-7 .tgl {
     display: none;
 }
.checkbox-wrapper-7 .tgl,
.checkbox-wrapper-7 .tgl:after,
.checkbox-wrapper-7 .tgl:before,
.checkbox-wrapper-7 .tgl *,
.checkbox-wrapper-7 .tgl *:after,
.checkbox-wrapper-7 .tgl *:before,
.checkbox-wrapper-7 .tgl + .tgl-btn {
    box-sizing: border-box;
}
.checkbox-wrapper-7 .tgl::-moz-selection,
.checkbox-wrapper-7 .tgl:after::-moz-selection,
.checkbox-wrapper-7 .tgl:before::-moz-selection,
.checkbox-wrapper-7 .tgl *::-moz-selection,
.checkbox-wrapper-7 .tgl *:after::-moz-selection,
.checkbox-wrapper-7 .tgl *:before::-moz-selection,
.checkbox-wrapper-7 .tgl + .tgl-btn::-moz-selection,
.checkbox-wrapper-7 .tgl::selection,
.checkbox-wrapper-7 .tgl:after::selection,
.checkbox-wrapper-7 .tgl:before::selection,
.checkbox-wrapper-7 .tgl *::selection,
.checkbox-wrapper-7 .tgl *:after::selection,
.checkbox-wrapper-7 .tgl *:before::selection,
.checkbox-wrapper-7 .tgl + .tgl-btn::selection {
    background: none;
}
.checkbox-wrapper-7 .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.checkbox-wrapper-7 .tgl + .tgl-btn:after,
.checkbox-wrapper-7 .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}
.checkbox-wrapper-7 .tgl + .tgl-btn:after {
    left: 0;
}
.checkbox-wrapper-7 .tgl + .tgl-btn:before {
    display: none;
}
.checkbox-wrapper-7 .tgl:checked + .tgl-btn:after {
    left: 50%;
}

.checkbox-wrapper-7 .tgl-ios + .tgl-btn {
    background: #fbfbfb;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
    border: 1px solid #e8eae9;
    font-size: 0.7rem;
}
.checkbox-wrapper-7 .tgl-ios + .tgl-btn:after {
    border-radius: 2em;
    background: #fbfbfb;
    transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}
.checkbox-wrapper-7 .tgl-ios + .tgl-btn:hover:after {
    will-change: padding;
}
.checkbox-wrapper-7 .tgl-ios + .tgl-btn:active {
    box-shadow: inset 0 0 0 2em #e8eae9;
}
.checkbox-wrapper-7 .tgl-ios + .tgl-btn:active:after {
    padding-right: 0.8em;
}
.checkbox-wrapper-7 .tgl-ios:checked + .tgl-btn {
    background: #86d993;
}
.checkbox-wrapper-7 .tgl-ios:checked + .tgl-btn:active {
    box-shadow: none;
}
.checkbox-wrapper-7 .tgl-ios:checked + .tgl-btn:active:after {
    margin-left: -0.8em;
}

.dashboard-stats {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    gap: 20px;

    &__container {
        padding: 0px;
        display: grid;
        margin-top: 0;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
    }

    &__content {
        position: relative;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        background-color: #fff;
        border: 1px solid #EDEDED;
        width: 100%;
        color: #2E2E2E;
        cursor: pointer;
        padding: 0;
        display: flex;
        border-radius: 10px;
        min-height: 100px;
        height: 100px;
    }

    &__number {
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 0em;
    }

    &__more {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }

    &--vert {
        .dashboard-stats__number {
            color: #56E099;
        }

        .dashboard-stats__more {
            content: url('../Images/icons_dashboard_v4/plus_green.svg');
        }

        .dashboard-stats__content:hover {
            background-color: #D5F7E5;
        }
    }

    &--bleu {
        .dashboard-stats__number {
            color: #58A9FF;
        }

        .dashboard-stats__more {
            content: url('../Images/icons_dashboard_v4/plus_locator.svg');
        }

        .dashboard-stats__content:hover {
            background-color: #d5e5ff;
        }
    }
}

.dashboard-container-parameters {
    border: none;
    position: relative;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    grid-template-rows: 24px auto auto auto 32px;
    background-color: $background-color-primary;
    row-gap: 15px;
    border-radius: 10px;
    padding: 20px;
    .dashboard-container-logo {
        display: flex;
        flex-direction: row;
        row-gap: 10px;
        align-items: center;
        justify-content: space-around;
        position: relative;

        .dashboard-container-logo--item {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;

            &__label {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
            }

            &__img {
                border-radius: 10px;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                gap: 5px;

                img {
                    width: 55px;
                    height: 55px;
                    border-radius: 10px;
                }

                &-picto {
                    min-width: 17px;
                    width: 100%;
                    height: 17px;
                    border-radius: 100%;
                    top: 2px;
                    position: relative;
                }

                &-label {
                    font-weight: 600;
                }
            }
            &__logo {
                border-radius: 5px;
                height: 55px;
                width: 55px;
                background-size: contain !important;
                background-position: center center !important;
                background-repeat: no-repeat !important;
            }
        }

        &--police, &--color {
            .dashboard-container-logo--item {
                align-items: flex-start !important;
            }
        }
    }
}

.dashboard-container-article--dashboard{
    grid-template-rows: 24px auto;
}

.dashboard-container__right--informations-lep {
    border-radius: 10px;
    border: none;
    background: #F4F6F8;
    height: fit-content;
    grid-template-rows: 138.39px auto 32px;
    gap: 20px;

    .ebureau-section {
        border: none;
        background: transparent;
        padding: 0;
        gap: 10px;
        position: relative;
    }
    .dashboard-container-infos--params {
        grid-template-rows: 24px auto;
        min-height: initial;
        border-radius: 0;
        .dashboard-container-information {
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 10px;
            overflow-y: auto;
            margin: 0;
            max-height: 128px;
        }
    }

    .dashboard-container-form {
        padding: 0;
        display: flex;
        flex-flow: row nowrap;
        gap: 5px;

        .form-control {
            font-size: 12px;
            padding: 5px 10px;
        }
    }

    .bouton-principal {
        width: fit-content;
        margin: 0 auto;
    }
}

*::-webkit-scrollbar {
    height: 2px;
    width: 2px;
}

*::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #DFE9EB;
}

*::-webkit-scrollbar-track:hover {
    background-color: #B8C0C2;
}

*::-webkit-scrollbar-track:active {
    background-color: #B8C0C2;
}

*::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #2E2E2E;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #2E2E2E;
}

*::-webkit-scrollbar-thumb:active {
    background-color: #2E2E2E;
}

#sellsy_button {
    display: none;
}

#page-package-ia-source {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: start;

    .page-package-ia-source__item, .page-package-ia-source__item--inline {
        width: 100%;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: stretch;
        justify-content: center;
    }

    .page-package-ia-source__item--inline {
        flex-direction: row;
        align-items: center;
    }

    .page-package-ia-source__item--label {
        white-space: nowrap;
    }

    input, textarea {
        border-radius: 0;
        height: 28px;
        background-color: #363636;
        border: none;
        color: white;
    }

    input:focus, textarea:focus {
        background-color: #363636;
        color: white;
    }

    textarea {
        min-height: 100px;
    }

    .select2-container .select2-selection--single {
        background-color: #363636;
        border: none;
    }

    .select2-container .select2-selection--single .select2-selection__rendered {
        color: white;
    }

    .select2-container .select2-selection--single .select2-selection__rendere {
        color: white;
        line-height: 32px;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        margin-top: -7px;
    }
}

.ia-icon::before {
    content: url('../Images/icons_dashboard_v4/etoile_blanc.svg');
}

.ia-icon.gjs-pn-active::before {
    content: url('../Images/icons_dashboard_v4/etoile_jaune.svg');
}

.gjs-pn-panel.gjs-pn-views .gjs-pn-btn.ia-icon {
    order: 5;
}

.gjs-pn-btn {
    margin: 0 !important;
    min-height: 25px !important;
    min-width: 25px !important;
    font-size: 15px !important;
}

.modal-confirm-action {
    top: 40%;
}

.modal-ia, .modal-confirm-action
{
    .modal-content-container__header {
        display: flex;
        align-items: center;
        img {
            margin-right: 10px;
        }
        svg {
            width: 23px;
            height: 23px;
        }
        &--subtitle{
            flex-direction: row;
            display: flex;
            font-weight: 400;
        }
    }

    .modal-content-container {
        display: flex;
        flex-direction: column;
        gap: 30px;
        height: auto;

        &--welcome, &--onboarded {
            text-align: center;
        }

        &--connectNetworks{
            .form-group, .modal-confirm-action .modal-content-container .form-group{
                align-items: center !important;
            }
            .btn-linkedin {
                background: #0E76A8;
                border-radius: 0;
                color: #fff;
                border-width: 1px;
                border-style: solid;
                border-color: #084461;
            }
            .google-btn {
                display: inline-block;
                background-image: url('../Images/Lep/gmb/btn_connexion.png');
                width: 191px;
                height: 46px;
            }
        }
        &__header, .modal-header-container {
            font-size: 18px;
            font-weight: 700;
            line-height: 21.6px;
            text-align: center;

            .modal-header-container__subtitle {
                font-weight: 400;
                font-size: 14px;
                margin-top: 5px;
            }
            img {
                width: 23px;
            }

            &-close {
                position: absolute;
                top: 40px;
                right: 25px;
                height: 20px;
                width: 20px;
                cursor: pointer;
            }

            &-back {
                position: absolute;
                top: 40px;
                right: 50px;
                height: 20px;
                width: 20px;
                cursor: pointer;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .form-group {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 100%;
            align-items: center;
            position: relative;
            label,.label {
                flex-basis: 20%;
                margin: 0;
            }

            select, input, textarea {
                flex-basis: 80%;
            }
            .tgl-btn{
                flex-basis: auto;
            }
            .input-group-append {
                display: flex;
                align-items: center !important;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);

                button {
                    background: none !important;
                    border: none;

                    .fa-times::before {
                        content: url('../Images/icons_dashboard_v4/cross.svg');
                        position: relative;
                        top: 2px;
                    }

                    .fa-paperclip::before {
                        content: url('../Images/icons_dashboard_v4/alt.svg');
                        position: relative;
                        top: 2px;
                    }
                }

                .btn-open-file-explorer {
                    background: #EBEBEB !important;
                    padding: 0;
                    height: 22px;
                    width: 22px;
                    border-radius: 5px !important;
                }
            }

            .input-group-text {
                background-color: transparent;
                border: none !important;
                padding: 0;

                i {
                    border-radius: 100%;

                }
            }

            .colorpicker-input-addon:before {
                background: none;
            }
        }


        #field-task-title-error,
        #field-task-name-error,
        #field-task-societe-error,
        #field-task-logo-error,
        #field-task-logo-format-error,
        #field-task-primary-color-error,
        #field-task-secondary-color-error,
        #field-task-sellsy_ref-error,
        #field-task-name-error-edit,
        #field-task-dueDate-error,
        #field-task-mentionned-error,
        #field-task-description-error,
        #field-task-status-error,
        .field-onboarding-error {
            display: none;
            color: red;
            font-weight: 600;
        }

        input, select {
            border: 1px solid #EDEDED !important;
            padding: 12px 25px 12px 25px;
            border-radius: 10px !important;
            background-color: #FFFFFF !important;
            min-height: 47px;
        }

        input[type="file"] {
            border: none !important;
        }

        textarea {
            width: 100%;
            font-weight: 400;
            font-size: 12px;
            border: 1px solid #EDEDED !important;
            padding: 12px 25px 12px 25px;
            border-radius: 10px;
            min-height: 400px;
            background: #ffffff !important;
        }

        .select2-container .select2-selection--single {
            border: 1px solid #EDEDED !important;
            border-radius: 10px;
            min-height: 47px;
        }

        .select2-selection__rendered {
            min-height: 47px;
            line-height: 47px !important;
        }

        .select2-container .select2-selection--single .select2-selection__arrow {
            height: 47px;
        }

        .dropzone {
            border: 1px dashed #EDEDED !important;
            border-radius: 10px;
            color: #2e2e2e;
            min-height: auto !important;
            .dz-message {
                padding: 0 10px;
            }

            i {
                font-size: 30px;
            }

            h3 {
                font-size: 12px;
            }
        }

        .multi-checkbox {
            display: grid;
            gap: 10px;
            grid-template-columns: 1fr 1fr;
            .checkbox-choice{
                display: flex;
                align-items: center;
                gap: 20px;
            }
        }
        .choice {
            font-size: 14px;
            font-weight: 700;
            line-height: 16.8px;
            color: #2E2E2E;
            display: flex;
            flex-direction: column;
            gap: 10px;

            div {
                border: 1px solid #919191;
                padding: 10px;
                border-radius: 30px;
                font-weight: 400;
                width: fit-content;
                cursor: pointer;
                color: #919191;
                font-size: 12px;
                line-height: 14.4px;
            }
        }

        .choice-no-div {
            font-size: 14px;
            line-height: 16.8px;
            color: #2E2E2E;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .modal-result {
            min-height: 200px;
        }
        .modal-content-textarea {
            position: relative;

            &--first {
                color: #919191;
                font-size: 12px;
                font-weight: 400;
                line-height: 14.4px;
            }

            &--second {
                color: #2E2E2E;
                font-size: 12px;
                font-weight: 400;
                line-height: 14.4px;
            }
        }

        .modal-footer-container {
            justify-content: space-between;
        }
    }

    .last-trigger {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }
}

.modal-content-container--onboarding {
    margin: auto;
    max-width: 550px;
    align-items: center;
}

.modal-onboarding--actusite {
    .modal-content-container--societe {
        max-width: none;
    }

    .modal-content-container--socialNetworks {
        .modal-body-container {
            gap: 15px 50px;
        }

        .form-group {
            gap: 10px !important;
            justify-content: space-between !important;
            align-items: center !important;
            flex-direction: row !important;
        }
    }

    .modal-content-container--newsletter {
        input {
            padding: 8px 12px;
            border-color: #ededed;
            min-height: 30px;
        }

        input[type="file"] {
            width: auto;
            margin: auto;
            cursor: pointer;
        }
    }
    .modal-content-container--categories {
        #listingCategories {
            gap : 50px;
        }
    }
}

.ck > p {
    font-size: 12px !important;
    font-weight: 400 !important;
}

.ck > h2 {
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 15px 0 !important;
}

.ck > h3 {
    font-size: 12px !important;
    font-weight: 600 !important;
    margin-bottom: 10px !important;
}


.checkbox-wrapper-18 .round {
    position: relative;
}

.checkbox-wrapper-18 .round label {

    background-color: #fff;
    box-shadow: 0 0 2px #6C757D;
    border-radius: 50%;
    cursor: pointer;
    height: 15px;
    width: 15px;
    display: block;
    border: none;
    margin: 0
}

.checkbox-wrapper-18 .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    opacity: 0;
    position: absolute;
    transform: rotate(-45deg);
    height: 5px;
    left: 3px;
    top: 4px;
    width: 9px;
}

.checkbox-wrapper-18 .round input[type="checkbox"] {
    visibility: hidden;
    display: none;
    opacity: 0;
}

.checkbox-wrapper-18 .round input[type="checkbox"]:checked + label {
    background-color: #56E099;
}

.checkbox-wrapper-18 .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

.modal-onboarding{
    z-index: 999 !important;
    select, input, textarea {
        flex-basis: 100% !important;
        width: 100%;
    }
    .component-colorpicker{
        position: relative;
    }
    .form-group{
        margin: 0;
        flex-direction: column !important;
        align-items: baseline !important;
        gap: 5px !important;
    }
    .modal-body-container{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    #comptePerso{
        width: auto;
        min-height: auto;
    }
}

.content__table{
    .table-data {
        background-color: $color-white;
        border: 1px solid #EDEDED;
        border-radius: 20px;
        overflow: hidden;
        &__header{
            color: $color-black;
            text-align: center;
            font-weight: 600;
            &--roundedTopLeft{
                border-top-left-radius: 30px 30px;
            }
            &--roundedTopRight{
                border-top-right-radius: 30px 30px;
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid #EBEBEB;
            }
        }
        &__body-column{
            text-align: center;
            padding: 5px 0!important;
        }
        .td-container { // for actions buttons
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            .btn-table-data__container{
                display: flex;
                align-items: center;
                text-align: center;
                width: 30px;
                padding: .4rem 0;
                border-radius: 4px;
                img{
                    height: .8rem;
                    width: auto;
                    margin: auto;
                }
                &:hover{
                    background-color: $color-background-primary;
                }
            }
        }
        @media screen and (max-width: 1200px) {
            font-size: .7rem;
        }
    }
}

.multi-checkbox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    .checkbox-choice{
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

#dashboard-admin {
    height: 100%;

    a {
        color: initial;

        &:hover {
            font-weight: 600;
        }
    }
    .right-column {
        flex-basis: 20%;
        row-gap: 10px;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        .ebureau-picto-section {
            display: flex !important;
        }

        .dashboard-admin--item {
            grid-template-rows: 24px auto;
            overflow: initial;
            position: relative;
            &__content {
                display: flex;
                flex-direction: column;
                grid-gap: 10px;
                font-size: 12px;
                overflow-y: auto;
                .label {
                    font-weight: 600;
                }

                .socialAccount {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                }
            }
        }
        form {
            display: flex;
            flex-direction: column;
        }

        .form-group {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 10px !important;
            max-width: 200px;
            .field-dashboard-error{
                display: none;
                color: red;
                font-weight: 600;
            }


            input {
                border: 0.5px solid #CBCBCB;
                border-radius: 10px !important;
                gap: 0;
                color: #000000;
                font-size: 12px;
                font-weight: 700;
                line-height: 14.4px;
                text-align: left;
                padding: 10px !important;
            }

            .select2-container {
                min-height: 15px !important;
                font-size: 12px;
                font-weight: 700;
                line-height: 14.4px;
                text-align: left;
                color: #000000;

                .select2-selection--single {
                    border: 0.5px solid #CBCBCB;
                    border-radius: 10px;
                    gap: 0;
                    min-height: 15px !important;
                    color: #000000;
                }

                .select2-selection__rendered {
                    color: #000000;
                    min-height: 15px !important;
                }
            }
        }

        .notes__container {
            position: relative;
            margin: 0;

            div {
                font-weight: 600;
            }

            &-label {
                font-size: 14px;
            }

            &-value {
                position: relative;
                padding: 0;
                border-radius: 10px;

                textarea {
                    width: 100%;
                    border: none;
                    font-size: 12px;
                    resize: none;
                    position: relative;
                     height : 100px;
                     padding: 10px;
                     outline: none!important;
                }

                img {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    cursor: pointer;
                    width: 16px;
                    height: 16px;
                }

                .spinner-border {
                    position: absolute;
                    bottom: 31px;
                    right: 31px;
                    width: 16px;
                    height: 16px;
                    display: none;
                }
            }
        }
    }
    .left-column {
        flex-basis: 80%;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 0;
        gap: 20px;
        .disabled {
            gap: 0;
            background: #EBEBEB !important;
            color: #919191 !important;
            cursor: initial !important;
        }
        .ebureau-picto-section{
            display: flex !important;
        }
        .dashboard-admin--item {
            grid-template-rows: 24px auto;
            overflow: initial;
            position: relative;
            gap: 10px;

            &__commentary {
                display: flex;
                flex-direction: column;
                gap: 10px;
                overflow: auto;
                .commentary {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    &__content {
                        border: 1px solid #919191;
                        background-color: #F5F5F5;
                        border-radius: 10px;
                        width: 100%;
                        padding: 10px;
                        word-break: break-word;
                    }
                }
            }
            &__newsletter {
                border-top: 1px solid black;
                width: 100%;
                margin: 0 auto;
                padding-top: 10px;
                justify-items: center;
            }
            &__content {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-gap: 10px;
                font-size: 12px;

                .title {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    justify-content: center;
                    gap: 10px;
                    font-weight: 600;
                    font-size: 12px;
                    img{
                        width: 16px;
                    }
                }
                .label{
                    font-weight: 600;
                }
                .socialAccount{
                    display: flex;
                    gap: 10px;
                    align-items: center;
                }
                .body{
                    text-align: center;
                    font-weight: 600;
                }
                .color{
                    width: 13px;
                    height: 13px;
                    min-width: 13px;
                    border-radius: 100%;
                }
            }
        }

        .bloc-google {
            grid-template-columns: 3fr repeat(5, 1fr);
            .socialAccount {
                justify-content: center;
            }
        }
        .bloc-site{
            grid-template-columns: 4fr repeat(4, 1fr);

            .socialAccount {
                justify-content: center;
            }
        }
        .dashboard-animation {
            display: grid;
            grid-template-columns: 3fr 3fr 1fr 1fr 3fr 1fr 1fr 3fr 1fr 1fr 3fr 1fr 1fr;
            gap: 5px;
            row-gap: 10px;
            font-size: 10px;
            &__header, &__profile {
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: center;
                justify-content: space-between;

            }
            img{
                width: 16px;
                height: 16px;
            }
            &__header-item, &__profile-label, &__profile-info {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 5px;
            }

            &__profile-count {
                text-align: center;
            }
            &__color{
                background-color: #FF5865;
                height: 13px;
                width: 13px;
                min-width: 13px;
                min-height: 13px;
                border-radius: 100%;
            }
        }
    }

    label {
        margin-bottom: 0 !important;
    }
    .ebureau-section {
        padding: 10px;
        gap: 15px;
    }

    .ebureau-titre-more {
        position: absolute;
        top: 18px;
        right: 15px;
        cursor: pointer;

        .minus {
            width: 10px;
            height: 10px;
        }

        .plus {
            width: 10px;
            height: 10px;
        }
    }
}

.filter-select{
    border-radius: 10px;
    padding: 5px;
    display: block;
    color: #6c757d;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    width: fit-content;
}